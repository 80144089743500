body {
  /* margin: 0;
    padding: 0; */
  overflow-x: hidden;
  background-color: #ececec;
}

.contact-form {
  margin-top: 5%;
  margin-left: 25%;
  margin-right: 25%;
  height: 75%;
}

@media (max-width: 600px) {
  .contact-form {
    margin: 2%;
  }
}
